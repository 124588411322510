import { FacebookIcon } from './FacebookIcon'
import { InstagramIcon } from './InstagramIcon'
import { GitHubIcon } from './GitHubIcon'
import { LinkedInIcon } from './LinkedInIcon'
import { ArrowIcon } from './ArrowIcon'
import { ChevronIcon } from './ChevronIcon'
import { SpotifyIcon } from './SpotifyIcon'
import { AppleMusicIcon } from './AppleMusicIcon'
import { YouTubeIcon } from './YouTubeIcon'

export const icons = {
  facebook: FacebookIcon,
  instagram: InstagramIcon,
  github: GitHubIcon,
  linkedin: LinkedInIcon,
  arrow: ArrowIcon,
  chevron: ChevronIcon,
  spotify: SpotifyIcon,
  apple: AppleMusicIcon,
  youtube: YouTubeIcon,
} as const

export type IconName = keyof typeof icons

export type { BaseIconProps } from './types'
export {
  FacebookIcon,
  InstagramIcon,
  GitHubIcon,
  LinkedInIcon,
  ArrowIcon,
  ChevronIcon,
  SpotifyIcon,
  AppleMusicIcon,
  YouTubeIcon,
}
