import type { ButtonProps } from '@/components/ui/Button'
import { buttonVariants } from '@/components/ui/Button'
import { cn } from '@/utils/ui'
import { ChevronIcon } from '@/icons/index'
import * as React from 'react'
import { cva } from 'class-variance-authority'

/**
 * @file Pagination Component
 * @description A pagination component that provides a set of navigation links for traversing through pages.
 *
 * @see PaginationProps for a list of available props
 *
 * @returns {React.ReactElement} A pagination component with navigation links.
 */

/**
 * @interface PaginationProps
 * @description Defines the props for the Pagination component.
 * @extends React.ComponentProps<'nav'>
 *
 * @property {string} [className] - Optional CSS class name for the component.
 */
interface PaginationProps extends React.ComponentProps<'nav'> {
  className?: string
}

const paginationVariants = cva('mx-auto flex w-full justify-center', {
  variants: {},
  defaultVariants: {},
})

const Pagination = ({ className, ...props }: PaginationProps) => (
  <nav
    aria-label="pagination"
    className={cn(paginationVariants({ className }))}
    role="navigation"
    {...props}
  />
)
Pagination.displayName = 'Pagination'

/**
 * @interface PaginationContentProps
 * @description Defines the props for the PaginationContent component.
 * @extends React.ComponentProps<'ul'>
 *
 * @property {string} [className] - Optional CSS class name for the component.
 */
interface PaginationContentProps extends React.ComponentProps<'ul'> {
  className?: string
}

const paginationContentVariants = cva('flex flex-row items-center gap-1', {
  variants: {},
  defaultVariants: {},
})

const PaginationContent = React.forwardRef<HTMLUListElement, PaginationContentProps>(
  ({ className, ...props }, ref) => (
    <ul className={cn(paginationContentVariants({ className }))} ref={ref} {...props} />
  ),
)
PaginationContent.displayName = 'PaginationContent'

/**
 * @interface PaginationItemProps
 * @description Defines the props for the PaginationItem component.
 * @extends React.ComponentProps<'li'>
 *
 * @property {string} [className] - Optional CSS class name for the component.
 */
interface PaginationItemProps extends React.ComponentProps<'li'> {
  className?: string
}

const paginationItemVariants = cva('', {
  variants: {},
  defaultVariants: {},
})

/**
 * @const PaginationItem
 * @description A component that renders a single item in the pagination, typically a page number link.
 *
 * @param {PaginationItemProps} props - The props for the PaginationItem component.
 *
 * @returns {React.ReactElement} A li element with the specified styles.
 */
const PaginationItem = React.forwardRef<HTMLLIElement, PaginationItemProps>(
  ({ className, ...props }, ref) => (
    <li className={cn(paginationItemVariants({ className }))} ref={ref} {...props} />
  ),
)
PaginationItem.displayName = 'PaginationItem'

/**
 * @interface PaginationLinkProps
 * @description Defines the props for the PaginationLink component.
 * @extends React.ComponentProps<'button'>
 *
 * @property {boolean} [isActive] - Whether the link is currently active.
 * @property {ButtonProps['size']} [size] - The size of the button.
 * @property {string} [className] - Optional CSS class name for the component.
 */
interface PaginationLinkProps extends React.ComponentProps<'button'> {
  isActive?: boolean
  size?: ButtonProps['size']
  className?: string
}

/**
 * @const PaginationLink
 * @description A component that renders a link to a specific page in the pagination.
 *
 * @param {PaginationLinkProps} props - The props for the PaginationLink component.
 *
 * @returns {React.ReactElement} A button element with the specified styles.
 */
const PaginationLink = ({ className, isActive, size = 'icon', ...props }: PaginationLinkProps) => (
  <button
    aria-current={isActive ? 'page' : undefined}
    className={cn(
      buttonVariants({
        size,
        variant: isActive ? 'primary' : 'secondary',
      }),
      className,
    )}
    {...props}
  />
)
PaginationLink.displayName = 'PaginationLink'

/**
 * @interface PaginationPreviousProps
 * @description Defines the props for the PaginationPrevious component.
 * @extends React.ComponentProps<typeof PaginationLink>
 *
 * @property {string} [className] - Optional CSS class name for the component.
 */
interface PaginationPreviousProps extends React.ComponentProps<typeof PaginationLink> {
  className?: string
}

/**
 * @const PaginationPrevious
 * @description A component that renders a link to the previous page in the pagination.
 *
 * @param {PaginationPreviousProps} props - The props for the PaginationPrevious component.
 *
 * @returns {React.ReactElement} A PaginationLink component with the specified styles.
 */
const PaginationPrevious = ({ className, ...props }: PaginationPreviousProps) => (
  <PaginationLink
    aria-label="Go to previous page"
    className={cn('gap-1 pl-2.5', className)}
    size="default"
    {...props}
  >
    <ChevronIcon width={16} height={16} style={{ transform: 'rotate(180deg)' }} />
    <span>Previous</span>
  </PaginationLink>
)
PaginationPrevious.displayName = 'PaginationPrevious'

/**
 * @interface PaginationNextProps
 * @description Defines the props for the PaginationNext component.
 * @extends React.ComponentProps<typeof PaginationLink>
 *
 * @property {string} [className] - Optional CSS class name for the component.
 */
interface PaginationNextProps extends React.ComponentProps<typeof PaginationLink> {
  className?: string
}

/**
 * @const PaginationNext
 * @description A component that renders a link to the next page in the pagination.
 *
 * @param {PaginationNextProps} props - The props for the PaginationNext component.
 * @param {string} [props.className] - Optional CSS class name for the component.
 *
 * @returns {React.ReactElement} A PaginationLink component with the specified styles.
 */
const PaginationNext = ({ className, ...props }: PaginationNextProps) => (
  <PaginationLink
    aria-label="Go to next page"
    className={cn('gap-1 pr-2.5', className)}
    size="default"
    {...props}
  >
    <span>Next</span>
    <ChevronIcon width={16} height={16} />
  </PaginationLink>
)
PaginationNext.displayName = 'PaginationNext'

/**
 * @interface PaginationEllipsisProps
 * @description Defines the props for the PaginationEllipsis component.
 * @extends React.ComponentProps<'span'>
 *
 * @property {string} [className] - Optional CSS class name for the component.
 */
interface PaginationEllipsisProps extends React.ComponentProps<'span'> {
  className?: string
}

const paginationEllipsisVariants = cva('flex h-9 w-9 items-center justify-center', {
  variants: {},
  defaultVariants: {},
})

/**
 * @const PaginationEllipsis
 * @description A component that renders an ellipsis (...) to indicate that there are more pages in the pagination.
 *
 * @param {PaginationEllipsisProps} props - The props for the PaginationEllipsis component.
 *
 * @returns {React.ReactElement} A span element with the specified styles.
 */
const PaginationEllipsis = ({ className, ...props }: PaginationEllipsisProps) => (
  <span aria-hidden className={cn(paginationEllipsisVariants({ className }))} {...props}>
    <span className="text-lg">&hellip;</span>
    <span className="sr-only">More pages</span>
  </span>
)
PaginationEllipsis.displayName = 'PaginationEllipsis'

export {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
}
