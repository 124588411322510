import React from 'react'
import type { BaseIconProps } from '../types'

export function ChevronIcon({ className, style, width = 24, height = 24 }: BaseIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 27"
      fill="none"
      stroke="currentColor"
      className={className}
      style={style}
    >
      <path d="M1.40625 0.738037L14.1682 13.4999L1.40625 26.2618" />
    </svg>
  )
}
